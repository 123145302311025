.input-file-biovert-container {
  width: 100%;
  height: 50px;
  background-color: #a0a0a0;
  border: none;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  color: white;
}
