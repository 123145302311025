.select-multiple-pastilla-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.select-multiple-pastilla-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.select-multiple-pastilla-titulo-botones {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.select-multiple-pastilla-titulo-boton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 240px;
}

.select-multiple-pastilla-spacer {
  width: 10px;
}

.select-multiple-pastilla-vertical-spacer {
  width: 100%;
  height: 20px;
}

.select-multiple-pastilla-small-vertical-spacer {
  width: 100%;
  height: 5px;
}

.select-multiple-pastilla-lista {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  margin-top: 10px;
}

.select-multiple-pastilla-elemento {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  height: 40px;
  margin: 5px;
  width: 250px;
  max-width: 250px;
}

.select-multiple-pastilla-elemento-texto {
  width: 100%;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 15px;
}

.select-multiple-pastilla-elemento-eliminar {
  font-size: 30px;
  color: #7cb51c;
  padding-right: 10px;
  cursor: pointer;
  margin-left: 10px;
}

.select-multiple-pastilla-dialogo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 110;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}

.select-multiple-pastilla-dialogo-modal {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  width: 800px;
  height: 220px;
  padding: 10px;
}

.select-multiple-pastilla-dialogo-content {
  width: 100%;
  height: 100%;
}

.select-multiple-pastilla-dialogo-content-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.select-multiple-pastilla-dialogo-content-close {
  width: 30px;
  height: 50px;
  cursor: pointer;
}

.select-multiple-pastilla-dialogo-content-close-icon {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
}

.select-multiple-pastilla-dialogo-content-close-icon:hover, .select-multiple-pastilla-dialogo-content-close-icon:fous  {
  color: #000000;
  text-decoration: none;
}

.select-multiple-pastilla-dialogo-button-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
  margin-right: 20px;
}

.select-multiple-pastilla-dialogo-content-select-elemento {
  width: 100%;
  font-size: 15px;
  margin-left: 20px;
  padding-bottom: 10px;
}

.select-multiple-pastilla-dialogo-content-selects {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.select-multiple-pastilla-dialogo-select {
  width: 100%;
  font-size: 20px;
  text-overflow: ellipsis;
}