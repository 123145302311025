.select-paises-biovert-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.select-paises-biovert-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.select-paises-biovert-titulo-boton {
  margin-top: 10px;
  width: 240px;
}

.select-paises-biovert-spacer {
  width: 10px;
}

.select-paises-biovert-vertical-spacer {
  width: 100%;
  height: 20px;
}

.select-paises-biovert-small-vertical-spacer {
  width: 100%;
  height: 5px;
}

.select-paises-biovert-lista {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  margin-top: 10px;
}

.select-paises-biovert-elemento {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  height: 40px;
  margin: 5px;
  width: 170px;
  max-width: 200px;
}

.select-paises-biovert-elemento-texto {
  width: 100%;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 15px;
}

.select-paises-biovert-elemento-eliminar {
  font-size: 30px;
  color: #7cb51c;
  padding-right: 10px;
  cursor: pointer;
  margin-left: 10px;
}
