.dialogo-asociar-pais-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 110;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}

.dialogo-asociar-pais-modal {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  width: 800px;
  height: 480px;
  padding: 10px;
}

.dialogo-asociar-pais-content {
  width: 100%;
  height: 100%;
}

.dialogo-asociar-pais-content-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dialogo-asociar-pais-content-close {
  width: 30px;
  height: 50px;
  cursor: pointer;
}

.dialogo-asociar-pais-content-close-icon {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
}

.dialogo-asociar-pais-content-close-icon:hover, .dialogo-asociar-pais-content-close-icon:fous  {
  color: #000000;
  text-decoration: none;
}

.dialogo-asociar-pais-content-selects {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.dialogo-asociar-pais-vertical-spacer {
  height: 20px;
  width: 100%;
}

.dialogo-asociar-pais-select {
  width: 100%;
  font-size: 20px;
  text-overflow: ellipsis;
}

.dialogo-asociar-pais-button-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
  margin-right: 20px;
}
.dialogo-asociar-pais-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dialogo-asociar-pais-content-select-pais {
  width: 100%;
  font-size: 15px;
  margin-left: 20px;
}

.dialogo-asociar-pais-content-grupo {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 750px;
  height: 40px;
  margin-left: 20px;
  margin-top: 10px;
  padding-left: 10px;
}

.dialogo-asociar-pais-content-grupo:hover,
.dialogo-asociar-pais-content-grupo:focus {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #7cb51c;
  color: #000000;
  border-radius: 8px;
  background-color: #eeeeee;
  width: 750px;
  height: 40px;
  margin-left: 20px;
  margin-top: 10px;
  padding-left: 10px;
  cursor:pointer;
}
