.combinacion-de-productos-biovert-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.combinacion-de-productos-biovert-cabecera {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #747678;
  width: 100%;
  height: 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.combinacion-de-productos-biovert-contenido {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #e0e0e0;
  width: 100%;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.combinacion-de-productos-biovert-button-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-right: 5px;
  margin-top: 10px;
}

.combinacion-de-productos-biovert-textarea-input {
  font-size: 15px;
  width: 100%;
  box-sizing: border-box;
  height: 100px;
  resize: none;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  padding: 10px;
}

.combinacion-de-productos-biovert-productos {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.combinacion-de-productos-biovert-add-producto {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.combinacion-de-productos-biovert-select {
  width: 100%;
  height: 50px;
  font-size: 18px;
}

.combinacion-de-productos-biovert-producto-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  height: 60px;
  margin: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.combinacion-de-productos-biovert-producto-text{
  width: 100%;
  max-width: 400px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 15px;
}

.combinacion-de-productos-biovert-producto-eliminar {
  font-size: 30px;
  color: #7cb51c;
  padding-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.combinacion-de-productos-biovert-vertical-spacer {
  width: 100%;
  height: 10px;
}

.combinacion-de-productos-biovert-select-multiple-cultivos {
  width: 100%;
}
