.lineas-de-composicion-biovert-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.lineas-de-composicion-biovert-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.lineas-de-composicion-biovert-titulo-boton {
  margin-top: 10px;
  width: 240px;
}

.lineas-de-composicion-lista {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.lineas-de-composicion-linea {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 20px;
}

.lineas-de-composicion-linea-elemento {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 300px;
  height: 52px;
  margin: 5px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lineas-de-composicion-linea-elemento-small {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 130px;
  height: 52px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lineas-de-composicion-linea-elemento-small-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #ffffff;
  width: 130px;
  height: 52px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* monospace font para alinear decimales*/
  font-family: Consolas, 'Lucida Console', Monaco, "Courier New", Courier, monospace;
}

.lineas-de-composicion-linea-elemento-small-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #ffffff;
  width: 100px;
  height: 52px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lineas-de-composicion-linea-elemento-large {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 1190px;
  height: 52px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lineas-de-composicion-small-spacer {
  width: 5px;
}

.lineas-de-composicion-small-vertical-spacer {
  height: 5px;
}
