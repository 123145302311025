.app-container {
  width: 100%;
  height: 100%;
}

# Toastify toasts
.Toastify__toast--default { background: #fff; color: #aaa; }
.Toastify__toast--info { background: #3498db; }
.Toastify__toast--success { background: #7cb51c; }
.Toastify__toast--warning { background: #f1c40f; }
.Toastify__toast--error { background: #bb1414; }
.Toastify__toast-container { width: 400px; }
