
.frm-login-fondo {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1;

    display: block;
    background-image: url("../../static/bg.png");
    background-size: cover;
    width: 100%;
    height: 100%;

}

.frm-login-contenido {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 100;
    margin-left: 20px;
    margin-right: 20px;
}
