.ensayo-container {
  width: 100%;
  height: 100%;
}

.ensayo-detail-container {
  width: 100%;
  height: 100%;
}

.ensayo-formulario {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.ensayo-formulario-fila {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ensayo-formulario-elemento {
  width: 100%;
  margin-right: 25px;
}

.ensayo-formulario-elemento-titulo {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
}

.ensayo-formulario-elemento-contenido {
  width: 100%;
}

.ensayo-text-input {
  font-size: 20px;
  width: 100%;
}

.ensayo-textarea-input {
  font-size: 15px;
  width: 100%;
  height: 150px;
  resize: none;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  padding: 10px;
  margin-right: 10px;
}

.ensayo-breadcrumb-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.ensayo-formulario-vertical-spacer {
  width: 100%;
  height: 20px;
}