.barra-superior-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: #7cb51c;
  position:fixed;
  top:0;
  width:100%;
  z-index:100;
}

.barra-superior-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.barra-superior-logo {
  margin-left: 20px;
}

.barra-superior-big-add-button {
  margin-right: 20px;
  cursor: pointer;
}

.barra-superior-usuario {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 245px;
  height: 70px;
  background-color: #8fc03d;
  color: white;
  text-overflow: ellipsis;
  cursor: pointer;
}

.barra-superior-usuario:hover {
  background-color: #669616;
}

.barra-superior-usuario-nombre {
  margin-left: 20px;
}

.barra-superior-usuario-down-arrow {
  margin-right: 20px;
}

.barra-superior-big-add-button-dropdown {
  position: relative;
  display: inline-block;
}

.barra-superior-user-dropdown {
  position: relative;
  display: inline-block;
}

.big-add-button-dropdown-content-hide {
  display: none;
}

.barra-superior-user-dropdown-content-hide {
  display: none;
}

.big-add-button-dropdown-content-show {
  display: block;
  position: absolute;
  background-color: #ffffff;
  width: 340px;
  font-size: 16px;
  overflow: none;
  box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top: 60px;
  left: -276px;
}

.barra-superior-user-dropdown-content-show {
  display: block;
  position: absolute;
  background-color: #ffffff;
  width: 245px;
  font-size: 16px;
  overflow: none;
  box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top: 70px;
  left: 0px;
}

.barra-superior-dropdown-item {
  color: #7cb51c;
  border: none;
  text-decoration: none;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.barra-superior-dropdown-item-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  font-size: 16px;
}

.barra-superior-dropdown-item:hover {
  background-color: #e8e8e8;
}
