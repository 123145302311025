.greybox-biovert-container {
  margin: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 12px;
}

.greybox-biovert {
  width: 100%;
}
