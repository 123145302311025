.como-se-aplica-biovert-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  margin-top: 5px;
  margin-bottom: 10px;
}

.como-se-aplica-biovert-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}

.como-se-aplica-biovert-bloque-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.como-se-aplica-biovert-bloque-dosis {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.como-se-aplica-biovert-bloque-objetivo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.como-se-aplica-biovert-titulo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  font-size: 25px;
  color: black;
  margin-top: 10px;
  margin-bottom: 10px;
}

.como-se-aplica-biovert-subtitulo {
  font-size: 12px;
  color: grey;
  margin-top: 10px;
  margin-left: 10px;
}

.como-se-aplica-biovert-text-input {
  width: 100%;
  height: 50px;
  margin-left: 2px;
  margin-right: 2px;
  padding-left: 20px;
}

.como-se-aplica-biovert-text-area {
  width: 100%;
  height: 100px;
  margin-left: 2px;
  margin-right: 2px;
  resize: none;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  padding: 20px;
  margin-top: 10px;
}

.como-se-aplica-biovert-text-select {
  width: 100%;
  height: 62px;
  margin-left: 2px;
  margin-right: 2px;
  padding-left: 20px;
}

.como-se-aplica-biovert-boton-guardar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 15px;
}

.como-se-aplica-biovert-vertical-spacer {
  width: 100%;
  height: 20px;
}
