.producto-container {
  width: 100%;
  height: 100%;
}

.producto-formulario {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.producto-formulario-fila {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1;
}

.producto-formulario-elemento {
  width: 100%;
  margin-right: 25px;
  flex: 1;
}

.producto-formulario-elemento-titulo-seo {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 15px;
}

.producto-formulario-elemento-contenido-SEO {
  width: 98%;
  padding-left: 15px;
}

.producto-formulario-elemento-titulo {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
}

.producto-formulario-elemento-contenido {
  width: 100%;
}

.producto-text-input {
  font-size: 20px;
  width: 100%;
}

.producto-textarea-input {
  font-size: 15px;
  width: 100%;
  height: 150px;
  resize: none;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  padding: 10px;
  margin-right: 10px;
}

.producto-textarea-input-SEO {
  font-size: 15px;
  width: 100%;
  height: 75px;
  resize: none;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  padding: 10px;

  margin-bottom: 15px;
}

.producto-breadcrumb-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.producto-formulario-editor-descripcion {
  border-radius: 12px;
  background-color: #00dd00;
  width: 100%;
}

.producto-formulario-vertical-spacer {
  width: 100%;
  height: 20px;
}

.producto-formulario-editor-descripcion-wrapper-class {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  padding: 10px;
}

.producto-formulario-elemento-titulo-and-two-elements {
  flex: 1 1 25%;
}
