.estado-fenealogico-biovert-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.estado-fenealogico-biovert-cabecera {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #747678;
  width: 100%;
  height: 70px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.estado-fenealogico-biovert-cabecera-hide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #747678;
  width: 100%;
  height: 70px;
  border-radius: 8px;
}

.estado-fenealogico-biovert-foto-titulo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  width: 100%;
}

.estado-fenealogico-biovert-colapse {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 30px;
  width: 100%;
  height: 40px;
  font-size: 20px;
  color: white;
  cursor: pointer;
}

.estado-fenealogico-biovert-titulo-texto {
  font-size: 20px;
  margin-left: 10px;
  color: white;
}

.estado-fenealogico-biovert-titulo-arrow {
  font-size: 20px;
  margin-left: 5px;
  margin-right: 5px;
  color: white;
  cursor: pointer;
}

.estado-fenealogico-biovert-titulo-imagen {
  height: 50px;
  width: 50px;
  margin-top: 3px;
  margin-right: 15px;
  border-radius: 50%;
}

.estado-fenealogico-biovert-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #e0e0e0;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.estado-fenealogico-biovert-vertical-spacer {
  width: 100%;
  height: 20px;
}
