.cmpt-input-login-estado-ok {
    width: 350px;
    height: 50px;
    text-align: center;
    margin-bottom: 5px;
    border-radius: 5px;
    border: none;
}

.cmpt-input-login-estado-nok {
    width: 350px;
    height: 46px;
    text-align: center;
    margin-bottom: 5px;
    border-radius: 5px;
    border: none;
    border-bottom: 4px solid red;
}
