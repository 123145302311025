.dialogo-asociar-cultivo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 110;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}

.dialogo-asociar-cultivo-modal {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  width: 800px;
  height: 200px;
  padding: 10px;
}

.dialogo-asociar-cultivo-content {
  width: 100%;
  height: 100%;
}

.dialogo-asociar-cultivo-content-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dialogo-asociar-cultivo-content-close {
  width: 30px;
  height: 50px;
  cursor: pointer;
}

.dialogo-asociar-cultivo-content-close-icon {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
}

.dialogo-asociar-cultivo-content-close-icon:hover, .dialogo-asociar-cultivo-content-close-icon:fous  {
  color: #000000;
  text-decoration: none;
}

.dialogo-asociar-cultivo-content-selects {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.dialogo-asociar-cultivo-vertical-spacer {
  height: 20px;
  width: 100%;
}

.dialogo-asociar-cultivo-select {
  width: 100%;
  font-size: 20px;
  text-overflow: ellipsis;
}

.dialogo-asociar-cultivo-button-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
  margin-right: 20px;
}
