.select-cultivos-biovert-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.select-cultivos-biovert-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.select-cultivos-biovert-titulo-boton {
  margin-top: 10px;
  width: 240px;
}

.select-cultivos-biovert-spacer {
  width: 10px;
}

.select-cultivos-biovert-vertical-spacer {
  width: 100%;
  height: 20px;
}

.select-cultivos-biovert-small-vertical-spacer {
  width: 100%;
  height: 5px;
}

.select-cultivos-biovert-lista-de-cultivos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.select-cultivos-biovert-linea-cultivo {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 20px;
}

.select-cultivos-biovert-linea-cultivo-elemento {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 278px;
  height: 52px;
  margin: 5px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;

}
