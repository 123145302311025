/* -------------------- Select Box Styles: bavotasan.com Method (with special adaptations by ericrasch.com) */
/* -------------------- Source: http://bavotasan.com/2011/style-select-box-using-only-css/ */
.styled-select {
   background: url("../../static/dropdown-right-arrow.png") no-repeat 96% 0;
   height: 40px;
   overflow: hidden;
   width: 280px;
   margin: 10px 10px 10px 10px;
}

.styled-select select {
   background: transparent;
   border: none;
   font-size: 18px;
   height: 40px;
   padding: 5px;
   /* If you add too much padding here, the options won't show in IE */
   width: 300px;
}

.styled-select.slate select {
   border: 1px solid #cccccc;
   font-size: 16px;
   height: 34px;
   width: 268px;
}

/* -------------------- Rounded Corners */
.rounded {
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   border-radius: 20px;
}

.semi-square {
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   border-radius: 5px;
}

/* -------------------- Colors: Background */
.green {
   background-color: #7cb51c;
}

/* -------------------- Colors: Text */
.slate select {
   color: #000;
}

.green select {
   color: #fff;
}

.blue select {
   color: #fff;
}

.yellow select {
   color: #000;
}

.black select {
   color: #fff;
}


.green option {
   color: #000;
}