.cultivo-detail-container {
  width: 100%;
  height: 100%;
}

.cultivo-detalle-estados-fenologicos-container {
  width: 100%;
  height: 100%
}

.cultivo-detail-breadcrumb-seleccion-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
