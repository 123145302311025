.barra-lateral-biovert {
  width: 240px;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  top: 70px;
  overflow: scroll;
}

.barra-lateral-biovert-elemento {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 5px;
  width: 100%;
  background-color: #ffffff;
  color: black;
  text-decoration: none;
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
}

.barra-lateral-biovert-elemento:hover,
.barra-lateral-biovert-elemento:focus {
  background-color: #7cb51c;
  color: white;
  cursor: pointer;
}

.barra-lateral-biovert-elemento-submenu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  width: 100%;
  background-color: #eeeeee;
  color: black;
  text-decoration: none;
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
}

.barra-lateral-biovert-elemento-submenu:hover,
.barra-lateral-biovert-elemento-submenu:focus {
  background-color: #7cb51c;
  color: white;
  cursor: pointer;
}

.barra-lateral-biovert-elemento-icono {
  margin-left: 15px;
}

.barra-lateral-biovert-elemento-texto {
  margin-left: 10px;
}

.barra-lateral-biovert-elemento-texto-submenu {
  margin-left: 20px;
}

.barra-lateral-biovert-elemento-flecha {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin-right: 20px;
}

.barra-lateral-biovert-grupo-maestras {
  width: 100%;

}

@media screen and (max-width: 1440px) {
  .barra-lateral-biovert-grupo-maestras {
    width: 100%;
    overflow: scroll;
    height: 250px;

  }
}