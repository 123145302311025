.resultado-ensayo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.resultado-ensayo-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.resultado-ensayo-titulo-boton {
  margin-top: 10px;
  width: 240px;
}

.resultado-ensayo-lista {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.resultado-ensayo-linea {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 20px;
}

.resultado-ensayo-linea-elemento {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 200px;
  height: 52px;
  margin: 5px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resultado-ensayo-linea-elemento-small {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 100px;
  height: 52px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resultado-ensayo-linea-elemento-large {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 500px;
  height: 52px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resultado-ensayo-small-spacer {
  width: 5px;
}

.resultado-ensayo-small-vertical-spacer {
  height: 5px;
}

.resultado-ensayo-dialogo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 110;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}

.resultado-ensayo-dialogo-modal {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  width: 820px;
  height: 350px;
  padding: 10px;
}

.resultado-ensayo-dialogo-content {
  width: 100%;
  height: 100%;
}

.resultado-ensayo-dialogo-content-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.resultado-ensayo-dialogo-content-close {
  width: 30px;
  height: 50px;
  cursor: pointer;
}

.resultado-ensayo-dialogo-content-close-icon {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
}

.resultado-ensayo-dialogo-content-close-icon:hover,
.resultado-ensayo-dialogo-content-close-icon:focus  {
  color: #000000;
  text-decoration: none;
}

.resultado-ensayo-dialogo-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-right: 3px;
}

.resultado-ensayo-dialogo-content-opcion {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 790px;
  height: 140px;
  border: none;
  border-radius: 8px;
  padding: 10px;
  margin-left: 5px;
  margin-top: 10px;
}

.resultado-ensayo-dialogo-content-bloque {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.resultado-ensayo-dialogo-content-label {
  font-size: 16px;
  text-align: left;
  width: 140px;
}

.resultado-ensayo-dialogo-content-contenido {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.resultado-ensayo-dialogo-select-idioma {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 800px;
}

.resultado-ensayo-dialog-text-input {
  width: 600px;
  height: 28px;
}