.cmpt-form-login-contendor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.cmpt-form-login-centrar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent;
  min-width: 500px;
  min-height: 300px;
  border-radius: 10px;
}

.cmpt-form-login-texto1 {
  color: white;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.cmpt-form-login-vertical-spacer {
  height: 30px;
}
