.button-biovert-default {
  width: 231px;
  height: 51px;
  background-color: #a0a0a0;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 50px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.button-biovert-default:hover, .button-default:focus {
  background-color: #777777;
  box-shadow: 4px 4px #d9d6d6;
}

.button-biovert-default-text {
  width: 226px;
  height: 19px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
}

.button-biovert-primary {
  width: 231px;
  height: 51px;
  background-color: #7cb51c;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 50px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.button-biovert-primary:hover, .button-primary:focus {
  background-color: #2a8002;
  box-shadow: 4px 4px #d9d6d6;
}

.button-biovert-primary-text {
  width: 226px;
  height: 19px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
}

.button-biovert-primary-outline-only {
  height: 45px;
  width: 120px;
  background-color: Transparent;
  border: 2px solid;
  border-color: #7cb51c;
  border-radius: 20px;
  color: #7cb51c;
  text-align: center;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.button-biovert-primary-outline-only:hover, .button-biovert-primary-outline-only:focus {
  border: 0px;
  border-color: #639016;
  background-color: #639016;
  color: white;
}

.button-biovert-primary-fa-icon {
  width: 50px;
  height: 50px;
  background-color: #7cb51c;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  color: white;
  text-align: center;
  font-size: 25px;
}

.button-biovert-primary-fa-icon:hover, .button-biovert-primary-fa-icon:focus {
   background-color: #2a8002;
   box-shadow: 4px 4px #d9d6d6;
}

.button-biovert-secondary {
  width: auto;
  height: 51px;
  background-color: #ffffff;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 50px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}



.button-biovert-secondary-text {
  width: 226px;
  height: 19px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #7cb51c;
}