.pagina-biovert-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 70px;
}

.pagina-biovert-contenido {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.pagina-biovert-contenido-derecha {
  margin-left: 240px;
  width: 100%;
}

.pagina-biovert-beta-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: -webkit-linear-gradient(#83bf1f, #71a715);
  border-top-left-radius: 15px;
  width: 65px;
  height: 25px;
  z-index: 300;
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 12px;
  text-align: right;
  color: white;
}
