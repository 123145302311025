.cultivo-container {
  width: 100%;
  height: 100%;
}

.cultivo-breadcrumb-seleccion-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.cultivo-formulario {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 180px;
}

.cultivo-form-lbl-nombre {
  display: flex;
  align-items: center;
  height: 80px;
  margin-left: 20px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #000000;
}

.cultivo-form-nombre {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.cultivo-add-edit-text-input {
  font-size: 25px;
  width: 100%;
}

.cultivo-add-edit-grupo-de-cultivo-select {
  font-size: 25px;
  margin-right: 20px;
  width: 100%;
}

.cultivo-form-bloque {
  width: 100%;
}
