.breadcrumb-biovert {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 20px 20px 20px 20px;
}

.breadcrumb-biovert-default {
  color: #999999;
}

.breadcrumb-biovert-active {
  color: #000000;
}
