.perfil-container {
  width: 100%;
  box-sizing: border-box;
}

.perfil-datos {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}

.perfil-texto-label {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 10px;
}

.perfil-texto-texto {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #000000;
  margin-left: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.perfil-vertical-spacer {
  width: 100%;
  height: 20px;
}

.perfil-button-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  padding-bottom: 10px;
}
