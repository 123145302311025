.button-dropdown-button-container {
    position: relative;
    display: inline-block;
}

.button-dropdown-button {
  height: 45px;
  width: 120px;
  background-color: Transparent;
  border: 2px solid;
  border-color: #7cb51c;
  border-radius: 20px;
  color: #7cb51c;
  text-align: center;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.button-dropdown-button:hover, .button-dropdown-button:focus {
  border: 0px;
  border-color: #639016;
  background-color: #639016;
  color: white;
}

.button-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  font-size: 16px;
  overflow: auto;
  box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content-item {
  color: black;
  padding: 12px 16px;
  border: none;
  text-decoration: none;
  text-align: left;
  width: 100%;
  display: block;
  background-color: #f1f1f1;
}

.dropdown-content-item:hover {
  background-color: #dddddd;
}

.show {display: block;}
