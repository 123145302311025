.select-iconos-biovert-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.select-iconos-biovert-imagen {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  box-shadow: 2px 2px #d9d6d6;
}

.select-iconos-biovert-elemento {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #cccccc;
  cursor: pointer;
  border-radius: 8px;
  background-color: #ffffff;
  width: 278px;
  height: 60px;
  margin: 5px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.5;
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-duration: 0.3s;
}

.select-iconos-biovert-elemento:hover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  cursor: pointer;
  border-radius: 8px;
  background-color: #ffffff;
  width: 278px;
  height: 60px;
  margin: 5px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 1;
}

.select-iconos-biovert-elemento-active {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  cursor: pointer;
  border-radius: 8px;
  background-color: #ffffff;
  width: 278px;
  height: 60px;
  margin: 5px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 1;
}

.select-iconos-biovert-elemento-imagen {
    padding: 10px;
}

.select-iconos-biovert-elemento-texto {
    padding: 10px;
}
