.solucion-container {
  width: 100%;
  height: 100%;
}

.solucion-breadcrumb-seleccion-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.solucion-formulario {
  height: 320px;
}

.solucion-form-lbl-nombre {
  display: flex;
  align-items: center;
  height: 80px;
  margin-left: 20px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #000000;
}

.solucion-form-nombre {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.solucion-add-edit-text-input {
  font-size: 25px;
}

.solucion-add-edit-textarea-input {
  font-size: 15px;
  width: 100%;
  box-sizing: border-box;
  height: 100px;
  resize: none;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  padding: 10px;
}
