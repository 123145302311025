.aceptar-cancelar-biovert-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: 20px;
  margin-right: 20px;
}


.aceptar-cancelar-biovert {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.aceptar-cancelar-biovert-spacer {
  width: 20px;
  height: 20px;
}
