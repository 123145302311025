.tabla-biovert-container {
  background-color: #f5f5f5;
  border: none;
  border-radius: 8px;
  margin: 10px;
}

.tabla-biovert-cabecera {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 84px;
}

.tabla-biovert-elemento-cabecera {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 16px;
  color: #747678;
  width: 100%;
}

.tabla-biovert-elemento-cabecera-arrow {
  font-size: 16px;
  color: #7cb51c;
  margin-left: 8px;
}

.tabla-biovert-elemento-cabecera-align-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 16px;
  color: #747678;
  width: 100%;
}

.tabla-biovert-divider {
  background-color: #d8d8d8;
  height: 1px;
  width: 100%;
}

.tabla-biovert-buscar {
  height: 50px;
  width:100%;
  font-size: 20px;
}

.tabla-biovert-fila {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-duration: 0.3s;
}

.tabla-biovert-fila:hover {
  background-color: #f0f0f0;
}

.tabla-biovert-elemento-fila {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.tabla-biovert-elemento-fila-content {
  margin-left: 20px;
  margin-top: 20px;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #000000;
  cursor: pointer;
}

.tabla-biovert-elemento-fila-content-imagen {
  margin-left: 20px;
  cursor: pointer;
}

.tabla-biovert-elemento-fila-align-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #000000;
  width: 100%;
}

.tabla-biovert-no-hay-datos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.tabla-biovert-no-hay-datos-texto {
}

.tabla-biovert-elemento-fila-imagen {
  height: 45px;
  width: 45px;
  margin-top: 5px;
  border-radius: 50%;
  box-shadow: 2px 2px #d9d6d6;
}
