
html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  /* overflow: hidden; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  font-family: 'Trebuchet MS';
}

.magnitud-container {
  width: 100%;
  height: 100%;
}

.magnitud-breadcrumb-seleccion-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.magnitud-formulario {
  height: 180px;
}

.magnitud-form-lbl-valor {
  display: flex;
  align-items: center;
  height: 80px;
  margin-left: 20px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #000000;
}

.magnitud-form-valor {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.magnitud-add-edit-text-input {
  font-size: 25px;
}

.pagina-biovert-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 70px;
}

.pagina-biovert-contenido {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.pagina-biovert-contenido-derecha {
  margin-left: 240px;
  width: 100%;
}

.pagina-biovert-beta-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: -webkit-linear-gradient(#83bf1f, #71a715);
  border-top-left-radius: 15px;
  width: 65px;
  height: 25px;
  z-index: 300;
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 12px;
  text-align: right;
  color: white;
}

.barra-lateral-biovert {
  width: 240px;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  top: 70px;
  overflow: scroll;
}

.barra-lateral-biovert-elemento {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 5px;
  width: 100%;
  background-color: #ffffff;
  color: black;
  text-decoration: none;
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
}

.barra-lateral-biovert-elemento:hover,
.barra-lateral-biovert-elemento:focus {
  background-color: #7cb51c;
  color: white;
  cursor: pointer;
}

.barra-lateral-biovert-elemento-submenu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  width: 100%;
  background-color: #eeeeee;
  color: black;
  text-decoration: none;
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
}

.barra-lateral-biovert-elemento-submenu:hover,
.barra-lateral-biovert-elemento-submenu:focus {
  background-color: #7cb51c;
  color: white;
  cursor: pointer;
}

.barra-lateral-biovert-elemento-icono {
  margin-left: 15px;
}

.barra-lateral-biovert-elemento-texto {
  margin-left: 10px;
}

.barra-lateral-biovert-elemento-texto-submenu {
  margin-left: 20px;
}

.barra-lateral-biovert-elemento-flecha {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin-right: 20px;
}

.barra-lateral-biovert-grupo-maestras {
  width: 100%;

}

@media screen and (max-width: 1440px) {
  .barra-lateral-biovert-grupo-maestras {
    width: 100%;
    overflow: scroll;
    height: 250px;

  }
}
.barra-superior-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: #7cb51c;
  position:fixed;
  top:0;
  width:100%;
  z-index:100;
}

.barra-superior-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.barra-superior-logo {
  margin-left: 20px;
}

.barra-superior-big-add-button {
  margin-right: 20px;
  cursor: pointer;
}

.barra-superior-usuario {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 245px;
  height: 70px;
  background-color: #8fc03d;
  color: white;
  text-overflow: ellipsis;
  cursor: pointer;
}

.barra-superior-usuario:hover {
  background-color: #669616;
}

.barra-superior-usuario-nombre {
  margin-left: 20px;
}

.barra-superior-usuario-down-arrow {
  margin-right: 20px;
}

.barra-superior-big-add-button-dropdown {
  position: relative;
  display: inline-block;
}

.barra-superior-user-dropdown {
  position: relative;
  display: inline-block;
}

.big-add-button-dropdown-content-hide {
  display: none;
}

.barra-superior-user-dropdown-content-hide {
  display: none;
}

.big-add-button-dropdown-content-show {
  display: block;
  position: absolute;
  background-color: #ffffff;
  width: 340px;
  font-size: 16px;
  overflow: none;
  box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top: 60px;
  left: -276px;
}

.barra-superior-user-dropdown-content-show {
  display: block;
  position: absolute;
  background-color: #ffffff;
  width: 245px;
  font-size: 16px;
  overflow: none;
  box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top: 70px;
  left: 0px;
}

.barra-superior-dropdown-item {
  color: #7cb51c;
  border: none;
  text-decoration: none;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.barra-superior-dropdown-item-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  font-size: 16px;
}

.barra-superior-dropdown-item:hover {
  background-color: #e8e8e8;
}

.breadcrumb-biovert {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 20px 20px 20px 20px;
}

.breadcrumb-biovert-default {
  color: #999999;
}

.breadcrumb-biovert-active {
  color: #000000;
}

.tabla-biovert-container {
  background-color: #f5f5f5;
  border: none;
  border-radius: 8px;
  margin: 10px;
}

.tabla-biovert-cabecera {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 84px;
}

.tabla-biovert-elemento-cabecera {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 16px;
  color: #747678;
  width: 100%;
}

.tabla-biovert-elemento-cabecera-arrow {
  font-size: 16px;
  color: #7cb51c;
  margin-left: 8px;
}

.tabla-biovert-elemento-cabecera-align-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 16px;
  color: #747678;
  width: 100%;
}

.tabla-biovert-divider {
  background-color: #d8d8d8;
  height: 1px;
  width: 100%;
}

.tabla-biovert-buscar {
  height: 50px;
  width:100%;
  font-size: 20px;
}

.tabla-biovert-fila {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-duration: 0.3s;
}

.tabla-biovert-fila:hover {
  background-color: #f0f0f0;
}

.tabla-biovert-elemento-fila {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.tabla-biovert-elemento-fila-content {
  margin-left: 20px;
  margin-top: 20px;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #000000;
  cursor: pointer;
}

.tabla-biovert-elemento-fila-content-imagen {
  margin-left: 20px;
  cursor: pointer;
}

.tabla-biovert-elemento-fila-align-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #000000;
  width: 100%;
}

.tabla-biovert-no-hay-datos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.tabla-biovert-no-hay-datos-texto {
}

.tabla-biovert-elemento-fila-imagen {
  height: 45px;
  width: 45px;
  margin-top: 5px;
  border-radius: 50%;
  box-shadow: 2px 2px #d9d6d6;
}

.button-dropdown {
    position: relative;
    display: inline-block;
}

.button-dropbtn {
  height: 45px;
  width: 120px;
  background-color: Transparent;
  border: 2px solid;
  border-color: #7cb51c;
  border-radius: 20px;
  color: #7cb51c;
  text-align: center;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.button-dropbtn:hover, .button-dropbtn:focus {
    border: 0px;
    border-color: #639016;
    background-color: #639016;
    color: white;
}

.button-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    font-size: 16px;
    overflow: auto;
    box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content-item {
    color: black;
    padding: 12px 16px;
    border: none;
    text-decoration: none;
    text-align: left;
    width: 100%;
    display: block;
    background-color: #f1f1f1;
}

.dropdown-content-item:hover {
    background-color: #dddddd;
}

.show {display: block;}


/* -------------------- Select Box Styles: bavotasan.com Method (with special adaptations by ericrasch.com) */
/* -------------------- Source: http://bavotasan.com/2011/style-select-box-using-only-css/ */
.styled-select {
   background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAoCAYAAAD6xArmAAAABHNCSVQICAgIfAhkiAAAAJxJREFUSInt1rEKwjAQgOEfkYK4+DqFQheHPM9NNyWcz1Vo+2S6GFFLKY0ZHO6HTDk+MgQSTueLhhAClTvUBh122GGHHXZ4mYjIfSMREQB2vnmtqg5rqKoOQFsCA3QxxvEbjTGOQPeaKnylezObMmpmE9C/Dxx3grk5pWRN09wAUkoGzB8TP/4rrs+1qPTEuWFt4z/uscMOO+xw7R4aGHI14/PIOQAAAABJRU5ErkJggg==) no-repeat 96% 0;
   height: 40px;
   overflow: hidden;
   width: 280px;
   margin: 10px 10px 10px 10px;
}

.styled-select select {
   background: transparent;
   border: none;
   font-size: 18px;
   height: 40px;
   padding: 5px;
   /* If you add too much padding here, the options won't show in IE */
   width: 300px;
}

.styled-select.slate select {
   border: 1px solid #cccccc;
   font-size: 16px;
   height: 34px;
   width: 268px;
}

/* -------------------- Rounded Corners */
.rounded {
   border-radius: 20px;
}

.semi-square {
   border-radius: 5px;
}

/* -------------------- Colors: Background */
.green {
   background-color: #7cb51c;
}

/* -------------------- Colors: Text */
.slate select {
   color: #000;
}

.green select {
   color: #fff;
}

.blue select {
   color: #fff;
}

.yellow select {
   color: #000;
}

.black select {
   color: #fff;
}


.green option {
   color: #000;
}
.header-biovert-t1 {
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-left: 20px;
  color: #000000;
}

.button-biovert-default {
  width: 231px;
  height: 51px;
  background-color: #a0a0a0;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 50px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.button-biovert-default:hover, .button-default:focus {
  background-color: #777777;
  box-shadow: 4px 4px #d9d6d6;
}

.button-biovert-default-text {
  width: 226px;
  height: 19px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
}

.button-biovert-primary {
  width: 231px;
  height: 51px;
  background-color: #7cb51c;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 50px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.button-biovert-primary:hover, .button-primary:focus {
  background-color: #2a8002;
  box-shadow: 4px 4px #d9d6d6;
}

.button-biovert-primary-text {
  width: 226px;
  height: 19px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
}

.button-biovert-primary-outline-only {
  height: 45px;
  width: 120px;
  background-color: Transparent;
  border: 2px solid;
  border-color: #7cb51c;
  border-radius: 20px;
  color: #7cb51c;
  text-align: center;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.button-biovert-primary-outline-only:hover, .button-biovert-primary-outline-only:focus {
  border: 0px;
  border-color: #639016;
  background-color: #639016;
  color: white;
}

.button-biovert-primary-fa-icon {
  width: 50px;
  height: 50px;
  background-color: #7cb51c;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  color: white;
  text-align: center;
  font-size: 25px;
}

.button-biovert-primary-fa-icon:hover, .button-biovert-primary-fa-icon:focus {
   background-color: #2a8002;
   box-shadow: 4px 4px #d9d6d6;
}

.button-biovert-secondary {
  width: auto;
  height: 51px;
  background-color: #ffffff;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 50px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}



.button-biovert-secondary-text {
  width: 226px;
  height: 19px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #7cb51c;
}
.greybox-biovert-container {
  margin: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 12px;
}

.greybox-biovert {
  width: 100%;
}

.aceptar-cancelar-biovert-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: 20px;
  margin-right: 20px;
}


.aceptar-cancelar-biovert {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.aceptar-cancelar-biovert-spacer {
  width: 20px;
  height: 20px;
}

.icono-container {
  width: 100%;
  height: 100%;
}

.icono-breadcrumb-seleccion-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.icono-formulario {
  height: 280px;
}

.icono-form-lbl-nombre {
  display: flex;
  align-items: center;
  height: 80px;
  margin-left: 20px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #000000;
}

.icono-form-nombre {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.icono-add-edit-text-input {
  font-size: 25px;
}

.seccion-imagen-biovert-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.seccion-imagen-biovert {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  width: 100%;
}

.seccion-imagen-biovert-titulo {
  margin-top: 20px;
  margin-left: 20px;
  letter-spacing: 0.2px;
  text-align: left;
  font-size: 20px;
  color: #000000;
}

.seccion-imagen-biovert-elemento-imagen {
  margin: 20px;
}

.seccion-imagen-biovert-elemento-botones {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.seccion-imagen-biovert-imagen {
  height: auto;
  width: auto;
  max-width: 400px;
  max-height: 200px;
  border-radius: 8px;
  box-shadow: 5px 5px #d9d6d6;
}

.seccion-imagen-biovert-imagen-placeholder {
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  border: 2px solid #d8d8d8;
  border-radius: 8px;
}

.seccion-imagen-biovert-elemento-botones-eliminar {
  margin-right: 20px;
}

.input-file-biovert-container {
  width: 100%;
  height: 50px;
  background-color: #a0a0a0;
  border: none;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  color: white;
}

.familia-container {
  width: 100%;
  height: 100%;
}

.familia-breadcrumb-seleccion-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.familia-formulario {
  height: 400px;
}

.familia-form-grupo-h {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.familia-form-grupo-v {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.familia-form-lbl-nombre {
  display: flex;
  align-items: center;
  height: 60px;
  margin-left: 20px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #000000;
}

.familia-form-nombre {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.familia-add-edit-text-input {
  font-size: 25px;
}

.familia-add-edit-textarea-input {
  font-size: 15px;
  width: 100%;
  box-sizing: border-box;
  height: 100px;
  resize: none;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  padding: 10px;
}

.envase-container {
  width: 100%;
  height: 100%;
}

.envase-breadcrumb-seleccion-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.envase-formulario {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 180px;
}

.envase-form-lbl-nombre {
  display: flex;
  align-items: center;
  height: 80px;
  margin-left: 20px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #000000;
}

.envase-form-nombre {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.envase-add-edit-text-input {
  font-size: 25px;
  width: 100%;
}

.envase-add-edit-magnitud-select {
  font-size: 25px;
  margin-right: 20px;
  width: 100%;
}

.envase-form-bloque {
  width: 100%;
}

.componente-quimico-container {
  width: 100%;
  height: 100%;
}

.componente-quimico-breadcrumb-seleccion-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.componente-quimico-formulario {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
}

.componente-quimico-form-lbl-nombre {
  display: flex;
  align-items: center;
  height: 20px;
  margin-top: 20px;
  margin-left: 20px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #000000;
}

.componente-quimico-form-nombre {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.componentes-quimico-add-edit-text-input {
  font-size: 25px;
}

.componente-quimico-form-botones-indices {
    margin-left: 20px;
}

.componente-quimico-form-botones-indices-fila {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.componente-quimico-form-botones-indices-boton {
  margin: 4px;
  border: 1px solid black;
  width: 20px;
  height: 25px;
  background-color: #e3e3e3;
  cursor: pointer;
  text-align: center;
}

.cultivo-container {
  width: 100%;
  height: 100%;
}

.cultivo-breadcrumb-seleccion-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.cultivo-formulario {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 180px;
}

.cultivo-form-lbl-nombre {
  display: flex;
  align-items: center;
  height: 80px;
  margin-left: 20px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #000000;
}

.cultivo-form-nombre {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.cultivo-add-edit-text-input {
  font-size: 25px;
  width: 100%;
}

.cultivo-add-edit-grupo-de-cultivo-select {
  font-size: 25px;
  margin-right: 20px;
  width: 100%;
}

.cultivo-form-bloque {
  width: 100%;
}

.estado-fenologico-container {
  width: 100%;
  height: 100%;
}

.estado-fenologico-breadcrumb-seleccion-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.estado-fenologico-formulario {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 180px;
}

.estado-fenologico-form-lbl-nombre {
  display: flex;
  align-items: center;
  height: 80px;
  margin-left: 20px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #000000;
}

.estado-fenologico-form-nombre {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.estado-fenologico-add-edit-text-input {
  font-size: 25px;
  width: 100%;
}

.estado-fenologico-add-edit-cultivo-select {
  font-size: 25px;
  margin-right: 20px;
  width: 100%;
}

.estado-fenologico-form-bloque {
  width: 100%;
}

.solucion-container {
  width: 100%;
  height: 100%;
}

.solucion-breadcrumb-seleccion-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.solucion-formulario {
  height: 320px;
}

.solucion-form-lbl-nombre {
  display: flex;
  align-items: center;
  height: 80px;
  margin-left: 20px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #000000;
}

.solucion-form-nombre {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.solucion-add-edit-text-input {
  font-size: 25px;
}

.solucion-add-edit-textarea-input {
  font-size: 15px;
  width: 100%;
  box-sizing: border-box;
  height: 100px;
  resize: none;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  padding: 10px;
}

.producto-container {
  width: 100%;
  height: 100%;
}

.producto-formulario {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.producto-formulario-fila {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1 1;
}

.producto-formulario-elemento {
  width: 100%;
  margin-right: 25px;
  flex: 1 1;
}

.producto-formulario-elemento-titulo-seo {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 15px;
}

.producto-formulario-elemento-contenido-SEO {
  width: 98%;
  padding-left: 15px;
}

.producto-formulario-elemento-titulo {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
}

.producto-formulario-elemento-contenido {
  width: 100%;
}

.producto-text-input {
  font-size: 20px;
  width: 100%;
}

.producto-textarea-input {
  font-size: 15px;
  width: 100%;
  height: 150px;
  resize: none;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  padding: 10px;
  margin-right: 10px;
}

.producto-textarea-input-SEO {
  font-size: 15px;
  width: 100%;
  height: 75px;
  resize: none;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  padding: 10px;

  margin-bottom: 15px;
}

.producto-breadcrumb-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.producto-formulario-editor-descripcion {
  border-radius: 12px;
  background-color: #00dd00;
  width: 100%;
}

.producto-formulario-vertical-spacer {
  width: 100%;
  height: 20px;
}

.producto-formulario-editor-descripcion-wrapper-class {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  padding: 10px;
}

.producto-formulario-elemento-titulo-and-two-elements {
  flex: 1 1 25%;
}

.loading-biovert-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 200;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
}

.loading-biovert-element {
  font-size: 40px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: white;
}

.select-paises-biovert-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.select-paises-biovert-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.select-paises-biovert-titulo-boton {
  margin-top: 10px;
  width: 240px;
}

.select-paises-biovert-spacer {
  width: 10px;
}

.select-paises-biovert-vertical-spacer {
  width: 100%;
  height: 20px;
}

.select-paises-biovert-small-vertical-spacer {
  width: 100%;
  height: 5px;
}

.select-paises-biovert-lista {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  margin-top: 10px;
}

.select-paises-biovert-elemento {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  height: 40px;
  margin: 5px;
  width: 170px;
  max-width: 200px;
}

.select-paises-biovert-elemento-texto {
  width: 100%;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 15px;
}

.select-paises-biovert-elemento-eliminar {
  font-size: 30px;
  color: #7cb51c;
  padding-right: 10px;
  cursor: pointer;
  margin-left: 10px;
}

.dialogo-asociar-pais-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 110;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}

.dialogo-asociar-pais-modal {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  width: 800px;
  height: 480px;
  padding: 10px;
}

.dialogo-asociar-pais-content {
  width: 100%;
  height: 100%;
}

.dialogo-asociar-pais-content-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dialogo-asociar-pais-content-close {
  width: 30px;
  height: 50px;
  cursor: pointer;
}

.dialogo-asociar-pais-content-close-icon {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
}

.dialogo-asociar-pais-content-close-icon:hover, .dialogo-asociar-pais-content-close-icon:fous  {
  color: #000000;
  text-decoration: none;
}

.dialogo-asociar-pais-content-selects {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.dialogo-asociar-pais-vertical-spacer {
  height: 20px;
  width: 100%;
}

.dialogo-asociar-pais-select {
  width: 100%;
  font-size: 20px;
  text-overflow: ellipsis;
}

.dialogo-asociar-pais-button-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
  margin-right: 20px;
}
.dialogo-asociar-pais-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dialogo-asociar-pais-content-select-pais {
  width: 100%;
  font-size: 15px;
  margin-left: 20px;
}

.dialogo-asociar-pais-content-grupo {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 750px;
  height: 40px;
  margin-left: 20px;
  margin-top: 10px;
  padding-left: 10px;
}

.dialogo-asociar-pais-content-grupo:hover,
.dialogo-asociar-pais-content-grupo:focus {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #7cb51c;
  color: #000000;
  border-radius: 8px;
  background-color: #eeeeee;
  width: 750px;
  height: 40px;
  margin-left: 20px;
  margin-top: 10px;
  padding-left: 10px;
  cursor:pointer;
}

.select-iconos-biovert-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.select-iconos-biovert-imagen {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  box-shadow: 2px 2px #d9d6d6;
}

.select-iconos-biovert-elemento {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #cccccc;
  cursor: pointer;
  border-radius: 8px;
  background-color: #ffffff;
  width: 278px;
  height: 60px;
  margin: 5px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.5;
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-duration: 0.3s;
}

.select-iconos-biovert-elemento:hover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  cursor: pointer;
  border-radius: 8px;
  background-color: #ffffff;
  width: 278px;
  height: 60px;
  margin: 5px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 1;
}

.select-iconos-biovert-elemento-active {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  cursor: pointer;
  border-radius: 8px;
  background-color: #ffffff;
  width: 278px;
  height: 60px;
  margin: 5px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 1;
}

.select-iconos-biovert-elemento-imagen {
    padding: 10px;
}

.select-iconos-biovert-elemento-texto {
    padding: 10px;
}

.select-envases-biovert-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.select-envases-biovert-imagen {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  box-shadow: 2px 2px #d9d6d6;
}

.select-envases-biovert-elemento {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #cccccc;
  cursor: pointer;
  border-radius: 8px;
  background-color: #ffffff;
  width: 278px;
  height: 60px;
  margin: 5px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.5;
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-duration: 0.3s;
}

.select-envases-biovert-elemento:hover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  cursor: pointer;
  border-radius: 8px;
  background-color: #ffffff;
  width: 278px;
  height: 60px;
  margin: 5px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 1;
}

.select-envases-biovert-elemento-active {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  cursor: pointer;
  border-radius: 8px;
  background-color: #ffffff;
  width: 278px;
  height: 60px;
  margin: 5px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 1;
}

.select-envases-biovert-elemento-imagen {
    padding: 10px;
}

.select-envases-biovert-elemento-texto {
    padding: 10px;
}

.lineas-de-composicion-biovert-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.lineas-de-composicion-biovert-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.lineas-de-composicion-biovert-titulo-boton {
  margin-top: 10px;
  width: 240px;
}

.lineas-de-composicion-lista {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.lineas-de-composicion-linea {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 20px;
}

.lineas-de-composicion-linea-elemento {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 300px;
  height: 52px;
  margin: 5px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lineas-de-composicion-linea-elemento-small {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 130px;
  height: 52px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lineas-de-composicion-linea-elemento-small-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #ffffff;
  width: 130px;
  height: 52px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* monospace font para alinear decimales*/
  font-family: Consolas, 'Lucida Console', Monaco, "Courier New", Courier, monospace;
}

.lineas-de-composicion-linea-elemento-small-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #ffffff;
  width: 100px;
  height: 52px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lineas-de-composicion-linea-elemento-large {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 1190px;
  height: 52px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lineas-de-composicion-small-spacer {
  width: 5px;
}

.lineas-de-composicion-small-vertical-spacer {
  height: 5px;
}

.dialogo-linea-de-composicion-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 110;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}

.dialogo-linea-de-composicion-modal {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  width: 820px;
  height: 290px;
  padding: 10px;
}

.dialogo-linea-de-composicion-content {
  width: 100%;
  height: 100%;
}

.dialogo-linea-de-composicion-content-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dialogo-linea-de-composicion-content-close {
  width: 30px;
  height: 50px;
  cursor: pointer;
}

.dialogo-linea-de-composicion-content-close-icon {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
}

.dialogo-linea-de-composicion-content-close-icon:hover,
.dialogo-linea-de-composicion-content-close-icon:fous  {
  color: #000000;
  text-decoration: none;
}

.dialogo-linea-de-composicion-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-right: 3px;
}

.dialogo-linea-de-composicion-buttons-opciones {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.dialogo-linea-de-composicion-buttons-opcion-componente {
  width: 130px;
  height: 30px;
  border: solid 1px #e3e3e3;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-left: 15px;
  padding-top: 10px;
  font-size: 18px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.dialogo-linea-de-composicion-buttons-opcion-componente:hover,
.dialogo-linea-de-composicion-buttons-opcion-componente:focus,
.dialogo-linea-de-composicion-buttons-opcion-componente-active {
  width: 130px;
  height: 30px;
  border: solid 1px #e3e3e3;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-left: 15px;
  padding-top: 10px;
  font-size: 18px;
  background-color: #7cb51c;
  color: white;
  cursor: pointer;
}

.dialogo-linea-de-composicion-buttons-opcion-texto-libre {
  width: 110px;
  height: 30px;
  border: solid 1px #e3e3e3;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-left: 15px;
  padding-top: 10px;
  font-size: 18px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.dialogo-linea-de-composicion-buttons-opcion-texto-libre:hover,
.dialogo-linea-de-composicion-buttons-opcion-texto-libre:focus,
.dialogo-linea-de-composicion-buttons-opcion-texto-libre-active {
  width: 110px;
  height: 30px;
  border: solid 1px #e3e3e3;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-left: 15px;
  padding-top: 10px;
  font-size: 18px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  background-color: #7cb51c;
  color: white;
  cursor: pointer;
}

.dialogo-linea-de-composicion-content-opcion {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 790px;
  height: 140px;
  border: none;
  border-radius: 8px;
  padding: 10px;
  margin-left: 5px;
  margin-top: 10px;
}

.dialogo-linea-de-composicion-content-opcion-hidden {
  display: none;
}

.dialogo-linea-de-composicion-content-bloque {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dialogo-linea-de-composicion-content-label {
  font-size: 16px;
  text-align: left;
  width: 140px;
}

.dialogo-linea-de-composicion-content-contenido {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.dialogo-linea-de-composicion-content-select-componente {
  width: 650px;
  height: 40px;
}

.dialogo-linea-de-composicion-content-select-magnitud {
  width: 139px;
  height: 40px;
}

.dialogo-linea-de-composicion-content-select-input-text {
  width: 170px;
  height: 28px;
}

.dialogo-linea-de-composicion-content-select-idioma {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 800px;
}

.dialogo-linea-de-composicion-content-textarea {
  font-size: 15px;
  width: 765px;
  height: 150px;
  resize: none;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  padding: 10px;
  margin-right: 10px;
}

.button-dropdown-button-container {
    position: relative;
    display: inline-block;
}

.button-dropdown-button {
  height: 45px;
  width: 120px;
  background-color: Transparent;
  border: 2px solid;
  border-color: #7cb51c;
  border-radius: 20px;
  color: #7cb51c;
  text-align: center;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.button-dropdown-button:hover, .button-dropdown-button:focus {
  border: 0px;
  border-color: #639016;
  background-color: #639016;
  color: white;
}

.button-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  font-size: 16px;
  overflow: auto;
  box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content-item {
  color: black;
  padding: 12px 16px;
  border: none;
  text-decoration: none;
  text-align: left;
  width: 100%;
  display: block;
  background-color: #f1f1f1;
}

.dropdown-content-item:hover {
  background-color: #dddddd;
}

.show {display: block;}

.select-cultivos-biovert-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.select-cultivos-biovert-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.select-cultivos-biovert-titulo-boton {
  margin-top: 10px;
  width: 240px;
}

.select-cultivos-biovert-spacer {
  width: 10px;
}

.select-cultivos-biovert-vertical-spacer {
  width: 100%;
  height: 20px;
}

.select-cultivos-biovert-small-vertical-spacer {
  width: 100%;
  height: 5px;
}

.select-cultivos-biovert-lista-de-cultivos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.select-cultivos-biovert-linea-cultivo {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 20px;
}

.select-cultivos-biovert-linea-cultivo-elemento {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 278px;
  height: 52px;
  margin: 5px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;

}

.dialogo-asociar-cultivo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 110;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}

.dialogo-asociar-cultivo-modal {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  width: 800px;
  height: 200px;
  padding: 10px;
}

.dialogo-asociar-cultivo-content {
  width: 100%;
  height: 100%;
}

.dialogo-asociar-cultivo-content-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dialogo-asociar-cultivo-content-close {
  width: 30px;
  height: 50px;
  cursor: pointer;
}

.dialogo-asociar-cultivo-content-close-icon {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
}

.dialogo-asociar-cultivo-content-close-icon:hover, .dialogo-asociar-cultivo-content-close-icon:fous  {
  color: #000000;
  text-decoration: none;
}

.dialogo-asociar-cultivo-content-selects {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.dialogo-asociar-cultivo-vertical-spacer {
  height: 20px;
  width: 100%;
}

.dialogo-asociar-cultivo-select {
  width: 100%;
  font-size: 20px;
  text-overflow: ellipsis;
}

.dialogo-asociar-cultivo-button-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
  margin-right: 20px;
}

.cultivo-container {
  width: 100%;
  height: 100%;
}

.cultivo-detail-container {
  width: 100%;
  height: 100%;
}

.cultivo-detalle-estados-fenologicos-container {
  width: 100%;
  height: 100%
}

.cultivo-detail-breadcrumb-seleccion-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.estado-fenealogico-biovert-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.estado-fenealogico-biovert-cabecera {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #747678;
  width: 100%;
  height: 70px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.estado-fenealogico-biovert-cabecera-hide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #747678;
  width: 100%;
  height: 70px;
  border-radius: 8px;
}

.estado-fenealogico-biovert-foto-titulo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  width: 100%;
}

.estado-fenealogico-biovert-colapse {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 30px;
  width: 100%;
  height: 40px;
  font-size: 20px;
  color: white;
  cursor: pointer;
}

.estado-fenealogico-biovert-titulo-texto {
  font-size: 20px;
  margin-left: 10px;
  color: white;
}

.estado-fenealogico-biovert-titulo-arrow {
  font-size: 20px;
  margin-left: 5px;
  margin-right: 5px;
  color: white;
  cursor: pointer;
}

.estado-fenealogico-biovert-titulo-imagen {
  height: 50px;
  width: 50px;
  margin-top: 3px;
  margin-right: 15px;
  border-radius: 50%;
}

.estado-fenealogico-biovert-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #e0e0e0;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.estado-fenealogico-biovert-vertical-spacer {
  width: 100%;
  height: 20px;
}

.como-se-aplica-biovert-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  margin-top: 5px;
  margin-bottom: 10px;
}

.como-se-aplica-biovert-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}

.como-se-aplica-biovert-bloque-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.como-se-aplica-biovert-bloque-dosis {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.como-se-aplica-biovert-bloque-objetivo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.como-se-aplica-biovert-titulo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  font-size: 25px;
  color: black;
  margin-top: 10px;
  margin-bottom: 10px;
}

.como-se-aplica-biovert-subtitulo {
  font-size: 12px;
  color: grey;
  margin-top: 10px;
  margin-left: 10px;
}

.como-se-aplica-biovert-text-input {
  width: 100%;
  height: 50px;
  margin-left: 2px;
  margin-right: 2px;
  padding-left: 20px;
}

.como-se-aplica-biovert-text-area {
  width: 100%;
  height: 100px;
  margin-left: 2px;
  margin-right: 2px;
  resize: none;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  padding: 20px;
  margin-top: 10px;
}

.como-se-aplica-biovert-text-select {
  width: 100%;
  height: 62px;
  margin-left: 2px;
  margin-right: 2px;
  padding-left: 20px;
}

.como-se-aplica-biovert-boton-guardar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 15px;
}

.como-se-aplica-biovert-vertical-spacer {
  width: 100%;
  height: 20px;
}

.ensayo-container {
  width: 100%;
  height: 100%;
}

.ensayo-detail-container {
  width: 100%;
  height: 100%;
}

.ensayo-formulario {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.ensayo-formulario-fila {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ensayo-formulario-elemento {
  width: 100%;
  margin-right: 25px;
}

.ensayo-formulario-elemento-titulo {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
}

.ensayo-formulario-elemento-contenido {
  width: 100%;
}

.ensayo-text-input {
  font-size: 20px;
  width: 100%;
}

.ensayo-textarea-input {
  font-size: 15px;
  width: 100%;
  height: 150px;
  resize: none;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  padding: 10px;
  margin-right: 10px;
}

.ensayo-breadcrumb-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.ensayo-formulario-vertical-spacer {
  width: 100%;
  height: 20px;
}
.select-multiple-pastilla-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.select-multiple-pastilla-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.select-multiple-pastilla-titulo-botones {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.select-multiple-pastilla-titulo-boton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 240px;
}

.select-multiple-pastilla-spacer {
  width: 10px;
}

.select-multiple-pastilla-vertical-spacer {
  width: 100%;
  height: 20px;
}

.select-multiple-pastilla-small-vertical-spacer {
  width: 100%;
  height: 5px;
}

.select-multiple-pastilla-lista {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  margin-top: 10px;
}

.select-multiple-pastilla-elemento {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  height: 40px;
  margin: 5px;
  width: 250px;
  max-width: 250px;
}

.select-multiple-pastilla-elemento-texto {
  width: 100%;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 15px;
}

.select-multiple-pastilla-elemento-eliminar {
  font-size: 30px;
  color: #7cb51c;
  padding-right: 10px;
  cursor: pointer;
  margin-left: 10px;
}

.select-multiple-pastilla-dialogo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 110;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}

.select-multiple-pastilla-dialogo-modal {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  width: 800px;
  height: 220px;
  padding: 10px;
}

.select-multiple-pastilla-dialogo-content {
  width: 100%;
  height: 100%;
}

.select-multiple-pastilla-dialogo-content-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.select-multiple-pastilla-dialogo-content-close {
  width: 30px;
  height: 50px;
  cursor: pointer;
}

.select-multiple-pastilla-dialogo-content-close-icon {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
}

.select-multiple-pastilla-dialogo-content-close-icon:hover, .select-multiple-pastilla-dialogo-content-close-icon:fous  {
  color: #000000;
  text-decoration: none;
}

.select-multiple-pastilla-dialogo-button-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
  margin-right: 20px;
}

.select-multiple-pastilla-dialogo-content-select-elemento {
  width: 100%;
  font-size: 15px;
  margin-left: 20px;
  padding-bottom: 10px;
}

.select-multiple-pastilla-dialogo-content-selects {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.select-multiple-pastilla-dialogo-select {
  width: 100%;
  font-size: 20px;
  text-overflow: ellipsis;
}
.resultado-ensayo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.resultado-ensayo-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.resultado-ensayo-titulo-boton {
  margin-top: 10px;
  width: 240px;
}

.resultado-ensayo-lista {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.resultado-ensayo-linea {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 20px;
}

.resultado-ensayo-linea-elemento {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 200px;
  height: 52px;
  margin: 5px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resultado-ensayo-linea-elemento-small {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 100px;
  height: 52px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resultado-ensayo-linea-elemento-large {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 500px;
  height: 52px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resultado-ensayo-small-spacer {
  width: 5px;
}

.resultado-ensayo-small-vertical-spacer {
  height: 5px;
}

.resultado-ensayo-dialogo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 110;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}

.resultado-ensayo-dialogo-modal {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  width: 820px;
  height: 350px;
  padding: 10px;
}

.resultado-ensayo-dialogo-content {
  width: 100%;
  height: 100%;
}

.resultado-ensayo-dialogo-content-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.resultado-ensayo-dialogo-content-close {
  width: 30px;
  height: 50px;
  cursor: pointer;
}

.resultado-ensayo-dialogo-content-close-icon {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
}

.resultado-ensayo-dialogo-content-close-icon:hover,
.resultado-ensayo-dialogo-content-close-icon:focus  {
  color: #000000;
  text-decoration: none;
}

.resultado-ensayo-dialogo-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-right: 3px;
}

.resultado-ensayo-dialogo-content-opcion {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 790px;
  height: 140px;
  border: none;
  border-radius: 8px;
  padding: 10px;
  margin-left: 5px;
  margin-top: 10px;
}

.resultado-ensayo-dialogo-content-bloque {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.resultado-ensayo-dialogo-content-label {
  font-size: 16px;
  text-align: left;
  width: 140px;
}

.resultado-ensayo-dialogo-content-contenido {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.resultado-ensayo-dialogo-select-idioma {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 800px;
}

.resultado-ensayo-dialog-text-input {
  width: 600px;
  height: 28px;
}
.adjunto-ensayo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.adjunto-ensayo-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.adjunto-ensayo-titulo-boton {
  margin-top: 10px;
  width: 240px;
}

.adjunto-ensayo-lista {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.adjunto-ensayo-linea {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: top;
  width: 100%;
  margin-bottom: 20px;
  margin-left: 20px;
}

.adjunto-ensayo-linea-elemento {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  width: 540px;
  height: 52px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.adjunto-ensayo-small-spacer {
  width: 5px;
}

.adjunto-ensayo-small-vertical-spacer {
  height: 5px;
}

.adjunto-ensayo-dialogo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 110;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}

.adjunto-ensayo-dialogo-modal {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  width: 820px;
  height: 350px;
  padding: 10px;
}

.adjunto-ensayo-dialogo-content {
  width: 100%;
  height: 100%;
}

.adjunto-ensayo-dialogo-content-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.adjunto-ensayo-dialogo-content-close {
  width: 30px;
  height: 50px;
  cursor: pointer;
}

.adjunto-ensayo-dialogo-content-close-icon {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
}

.adjunto-ensayo-dialogo-content-close-icon:hover,
.adjunto-ensayo-dialogo-content-close-icon:focus  {
  color: #000000;
  text-decoration: none;
}

.adjunto-ensayo-dialogo-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-right: 3px;
}

.adjunto-ensayo-dialogo-content-opcion {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 790px;
  height: 140px;
  border: none;
  border-radius: 8px;
  padding: 10px;
  margin-left: 5px;
  margin-top: 10px;
}

.adjunto-ensayo-dialogo-content-bloque {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.adjunto-ensayo-dialogo-content-label {
  font-size: 16px;
  text-align: left;
  width: 140px;
}

.adjunto-ensayo-dialogo-content-contenido {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.adjunto-ensayo-dialogo-select-idioma {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 800px;
}

.adjunto-ensayo-dialog-text-input {
  width: 600px;
  height: 28px;
}

.adjunto-ensayo-imagen-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 150px;
  width: 200px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 5px 5px #d9d6d6;
  overflow: hidden;
}

.adjunto-ensayo-imagen {
  height: auto;
  width: auto;
  max-width: 200;
  max-height: 150px;
}

.adjunto-ensayo-imagen-placeholder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 200px;
  background-color: #ffffff;
  margin-top: 55px;
  margin-left: 20px;
  margin-right: 20px;
}
.solucion-container {
  width: 100%;
  height: 100%;
}

.solucion-detail-container {
  width: 100%;
  height: 100%;
}

.solucion-detail-breadcrumb-seleccion-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.soluciones-detail-combinacion-de-productos {
  width: 100%;
  height: 100%;
}

.combinacion-de-productos-biovert-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.combinacion-de-productos-biovert-cabecera {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #747678;
  width: 100%;
  height: 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.combinacion-de-productos-biovert-contenido {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #e0e0e0;
  width: 100%;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.combinacion-de-productos-biovert-button-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-right: 5px;
  margin-top: 10px;
}

.combinacion-de-productos-biovert-textarea-input {
  font-size: 15px;
  width: 100%;
  box-sizing: border-box;
  height: 100px;
  resize: none;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  padding: 10px;
}

.combinacion-de-productos-biovert-productos {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.combinacion-de-productos-biovert-add-producto {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.combinacion-de-productos-biovert-select {
  width: 100%;
  height: 50px;
  font-size: 18px;
}

.combinacion-de-productos-biovert-producto-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  height: 60px;
  margin: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.combinacion-de-productos-biovert-producto-text{
  width: 100%;
  max-width: 400px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 15px;
}

.combinacion-de-productos-biovert-producto-eliminar {
  font-size: 30px;
  color: #7cb51c;
  padding-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.combinacion-de-productos-biovert-vertical-spacer {
  width: 100%;
  height: 10px;
}

.combinacion-de-productos-biovert-select-multiple-cultivos {
  width: 100%;
}

.solucion-container {
  width: 100%;
  height: 100%;
}

.ELEMENTOPRUEBA {
  height: auto !important;

}
.page-principal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 200px;
}

.perfil-container {
  width: 100%;
  box-sizing: border-box;
}

.perfil-datos {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}

.perfil-texto-label {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 10px;
}

.perfil-texto-texto {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #000000;
  margin-left: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.perfil-vertical-spacer {
  width: 100%;
  height: 20px;
}

.perfil-button-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  padding-bottom: 10px;
}


.frm-login-fondo {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1;

    display: block;
    background-image: url(/static/media/bg.5c8ef8b2.png);
    background-size: cover;
    width: 100%;
    height: 100%;

}

.frm-login-contenido {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 100;
    margin-left: 20px;
    margin-right: 20px;
}

.cmpt-form-login-contendor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.cmpt-form-login-centrar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent;
  min-width: 500px;
  min-height: 300px;
  border-radius: 10px;
}

.cmpt-form-login-texto1 {
  color: white;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.cmpt-form-login-vertical-spacer {
  height: 30px;
}

.cmpt-button-login {
    width: 350px;
    height: 51px;
    background-color: #7cb51c;
    border: none;
    border-radius: 12px;
    display: inline-block;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    margin-top: 10px;
}

.cmpt-button-login:hover {
    width: 350px;
    background-color: #2a8002;
    border: none;
    border-radius: 12px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.cmpt-button-login-disabled {
    width: 350px;
    cursor: not-allowed;
    background-color: #aaaaaa;
    border: none;
    border-radius: 12px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    margin-top: 10px;
}

.cmpt-input-login-estado-ok {
    width: 350px;
    height: 50px;
    text-align: center;
    margin-bottom: 5px;
    border-radius: 5px;
    border: none;
}

.cmpt-input-login-estado-nok {
    width: 350px;
    height: 46px;
    text-align: center;
    margin-bottom: 5px;
    border-radius: 5px;
    border: none;
    border-bottom: 4px solid red;
}

.app-container {
  width: 100%;
  height: 100%;
}

# Toastify toasts
.Toastify__toast--default { background: #fff; color: #aaa; }
.Toastify__toast--info { background: #3498db; }
.Toastify__toast--success { background: #7cb51c; }
.Toastify__toast--warning { background: #f1c40f; }
.Toastify__toast--error { background: #bb1414; }
.Toastify__toast-container { width: 400px; }

