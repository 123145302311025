.seccion-imagen-biovert-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.seccion-imagen-biovert {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  width: 100%;
}

.seccion-imagen-biovert-titulo {
  margin-top: 20px;
  margin-left: 20px;
  letter-spacing: 0.2px;
  text-align: left;
  font-size: 20px;
  color: #000000;
}

.seccion-imagen-biovert-elemento-imagen {
  margin: 20px;
}

.seccion-imagen-biovert-elemento-botones {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.seccion-imagen-biovert-imagen {
  height: auto;
  width: auto;
  max-width: 400px;
  max-height: 200px;
  border-radius: 8px;
  box-shadow: 5px 5px #d9d6d6;
}

.seccion-imagen-biovert-imagen-placeholder {
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  border: 2px solid #d8d8d8;
  border-radius: 8px;
}

.seccion-imagen-biovert-elemento-botones-eliminar {
  margin-right: 20px;
}
