.solucion-detail-container {
  width: 100%;
  height: 100%;
}

.solucion-detail-breadcrumb-seleccion-idioma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.soluciones-detail-combinacion-de-productos {
  width: 100%;
  height: 100%;
}
